.ardy-event-carousel-img {
  width: 217px;
  height: 130px;
  object-fit: contain;
  min-width: 100px;
  mix-blend-mode: multiply;
}

@media (max-width:1200px) {
  .ardy-event-carousel-img {
    width: 11vw;
  }
}