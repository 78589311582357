.button,
.label--btn,
.link--btn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    font-size: $font-size-20;
    gap: 8px;
    border: none;
    padding: 13px 26px;
    transition: 0.32s;
     @if $selected-language == "english" {
        @extend .font-suisseint-regular;
    }@else{
        @extend .font-mardoto-regular;
    }
}

.header--btn{
    padding: 8px 32px;
    font-size: $font-size-18;
}
.arrow--btn{
    img{
        transition: 0.3s;
        margin-left: 20px;
    }
    &:hover{
        img{
            margin-left: 30px;
        }
    }
}
.link--btn{
    text-decoration: none;
}
.label--btn{
    background-color: transparent;
    font-size: $font-size-18 !important;
    &:hover{
        text-decoration: underline;
    }
}
.dropdown--btn{
    border: none;
    background-color: transparent;
}

.primary--btn{
    background-color: $main-dark;
    color: $white;
}

.primary--btn.disabled--btn{
    background-color: $disabled !important;
}

.primary-outline--btn{
    background-color: $white !important;
    border: 2px solid $main-dark;
    &:hover{
        background-color: $main-dark !important;
        color: $white;
    }
}
.emphasys-outline--btn{
    background-color: $white !important;
    border: 2px solid $emphasis;
    &:hover{
        background-color: $emphasis !important;
        color: $white;
    }
}
.emphasys-pressed-outline--btn{
    background-color: $white !important;
    border: 2px solid $emphasis-pressed;
    &:hover{
        background-color: $emphasis-pressed !important;
        color: $white;
    }
}
.disabled-outline--btn{
    background-color: $white !important;
    border: 2px solid $disabled;
}
.white-outline--btn{
    background-color: transparent !important;
    border: 2px solid $white;
    color: $white;
    &:hover{
        background-color: $white !important;
        color: $main-dark;
    }
}

.primary-label--btn{
    color: $main-dark;
}
.emphasys-label--btn{
    color: $emphasis !important;
}
.emphasys-pressed-label--btn{
    color: $emphasis-pressed !important;
}
.disabled-label--btn{
    color: $disabled !important;
}
.gray-label--btn{
    color: $ardy--main-background !important;
}

.circal--btn{
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 8px 20px 0px rgba(48, 72, 158, 0.40);
}

.segmented--btn{
    background-color: $derivatives-bkg-lvl-3;
    border-radius: 54px;
    .button{
        background-color: transparent;
        
        &.active{
            background-color: $main-dark;
            color: $white;
            @extend .ardy--primary-box-shadow;
        }
    }
}

// Small devices (landscape phones, 576px and up) and Medium devices (tablets, 768px and up)
@media (min-width: 375px) and (max-width: 991.98px) {
    .button,
    .label--btn,
    .link--btn{
        width: 100%;
        font-size: $font-size-16;
    }

    .segmented--btn{
        .button{
            padding: 13px 20px;
        }
    }

    .primary--btn{
        &:hover:not(.disabled--btn){
            background-color: $emphasis-pressed;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {    
    .button,
    .label--btn,
    .link--btn{
        font-size: $font-size-20;
    }
    .primary--btn{
        &:hover:not(.disabled--btn){
            background-color: $emphasis;
            box-shadow: 0px 8px 20px 0px rgba($color: $main-dark, $alpha: .4);
        }
    }
    
    .segmented--btn{
        .button{
            padding: 13px 46px;
        }
    }
}