.ardy--card{
    border-radius: 20px;
    overflow: hidden;
    background-color: $white;
    @extend .ardy--primary-box-shadow;
    .ardy--card-image{
        width: 100%;
        height: 165px;
    }
    .ardy--card-body{
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        .ardy--vertical-date{
            color: $emphasis;
            font-size: 24px;
            width: fit-content;
            text-align: center;
            span{
                display: block;
                &:nth-child(1){
                    line-height: 35px;
                    font-weight: 500;
                }
                &:nth-child(3){
                    font-size: $font-size-16;
                }
                &:nth-child(2){
                    width: 100%;
                    height: 1px;
                    background-color: $emphasis;
                }
            }
        }
    }
}

.ardy--course-topic-card.ardy-course-image-card{
    .ardy--course-card-body{
        .ardy--course-card-info{
             position: absolute;
        }
    }
}

.ardy--course-topic-card{
    position: relative;
    margin-left: 25px;
    .ardy--course-card-title{
        position: absolute;
        transform: rotate(-90deg) translateX(-100%);
        transform-origin: left;
        left: -15px;
        padding: 0;
        top: 0;
        z-index: 1;
        text-align: end;
        &::after{
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            background-color: lighten($color: $main-dark, $amount: 40%);
            top: -2px;
            right: -10px;
        }
        h4{
            font-size: $font-size-14;
        }
    }
    .ardy--course-card-body{
        position: relative;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        .ardy--course-card-info{
            background-color: $derivatives-bkg-lvl-2;
            padding: 32px;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            transition: 0.3s;
            justify-content: space-between;
            .ardy--course-body-card-title{
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                h5{
                    @extend .ardy--text-line-2;
                }
            }
            .ardy--course-card-text{
                text-overflow: ellipsis;
                font-size: 16px;
                font-weight: 450;
                line-height: 26px;
                font-weight: normal;
                @extend .ardy--text-line-5;
            }
            a{
                margin-left: auto;
                display: block;
                img{
                    width: 18px;
                }
            }
        }

    }
}

@media (min-width: 834px){
    .ardy-course-image-card{
        .ardy--course-card-body{
            min-height: 350px;
            .ardy--course-card-info{
                width: calc(25vw - 30px);
            }
        }    
    }
    .ardy--course-card-body{
        .ardy--course-card-info{
            bottom: -20px;
            right: 0;
            min-height: 250px;
            a{
                .ardy--arrow-hover{
                    display: none;
                    opacity: 0;
                }
            }
            &:hover{
                 box-shadow: 0px 8px 20px 0px rgba($color: $main-dark, $alpha: .4);
                .ardy--arrow-hover{
                    display: block;
                    opacity: 1;
                }
                img:not(.ardy--arrow-hover){
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 833.98px){
    .ardy-course-image-card{
        .ardy--course-card-body{
            min-height: 450px;
        }    
    }
    .ardy--course-card-body{
        .ardy--course-card-info{
            bottom: 0;
            width: 100%;
            box-shadow: 0px 8px 20px 0px rgba($color: $main-dark, $alpha: .4);
            .ardy--arrow-hover{
                display: block;
                opacity: 1;
            }
            
            img:not(.ardy--arrow-hover){
                display: none;
            }
        }
    }
}

@media (max-width: 575px){
    .ardy--course-card-text {
        display: none !important;
    }
}