

.slider-wrapper {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    overflow: hidden;

    img {
        width: 100%;
    }

    .carousel-item {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 468px;
    }
}

.ardy--slider-section {
    @extend .ardy--gray-bkg-lvl-3;
    border-radius: 16px;
    position: relative;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 375px) and (max-width: 767.98px) {
    .ardy--slider-section {
        padding: 28px;
        border-radius: 16px;
        position: relative;
    }

    .carousel-inner {
        margin-bottom: 2rem;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 833.98px) {
    .ardy--slider-section {
        padding: 28px;
        border-radius: 16px;
        position: relative;

        .carousel-indicators {
            margin: 0 28px 28px 0;
            bottom: 0;
            right: 0;
            width: fit-content;
            left: auto;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 834px) {
    .ardy--slider-section {
        padding: 48px;
        border-radius: 16px;
        position: relative;

        .carousel-indicators {
            margin: 0 48px 15px 0;
            bottom: 0;
            right: 0;
            width: fit-content;
            left: auto;
        }
    }
}