body{
    background-color: $ardy--main-background;
}

a{
    font-size: $font-size-18;
    color: $main-dark;
    text-decoration: none;
}

.ardy--section-image-wrapper{
    img{
        width: 100%;
        max-width: 700px;
        border-radius: 25px;
    }
}

.ardy--gray-bottom-border{
    border-bottom: 1px solid $disabled;
}

.ardy--primary-box-shadow{
    box-shadow: 0px 8px 20px 0px rgba($color: $main-dark, $alpha: .4);
}
        
.ardy--image-background{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ardy--flex-1{
    flex: 1;
}

.ardy--w-fit-content{
    width: fit-content;
}

.ardy--aside-list{
    list-style: none;
    padding: 0;
    li{
        padding: 10px 0;
        display: flex;
        align-items: start;
        img{
            margin-right: 20px;
            margin-top: 8px;
        }
    }
}

.ardy--side-section{
    // border-top-left-radius: 50px;
    // border-bottom-left-radius: 50px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 4rem 3rem;
    @media (min-width: 768px){
        // margin-left: 6rem;
        margin-left: 4rem;
    }
    
    @media (min-width: 375px) and (max-width: 767.98px){
        // margin-left: 2rem;
        margin-left: 0;
        border-radius: 0;
    }
}

.ardy--negative-section{
    margin-bottom: -40px;
}

.ardy--designed-image,
 .ardy--designed-video {
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -50%;
        background-color: $emphasis;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    img, video{
        width: 100%;
    }
    video{
        background-color: $black;
    }
}

.ardy--cursor-pointer{
    cursor: pointer;
}

.ardy--text-line-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.ardy--text-line-5{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.ardy--modal{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 375px) and (max-width: 767.98px){
    .section-top{
        padding: 6rem 0;
    }
    .section{
        padding: 3rem 0;
    }
    .ardy--building-image-wrapper{
        height: 200px;
        img{
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 350px;
        }
    }

    a{
        font-size: $font-size-18 !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px){
    .section-top{
        padding: 7rem 0;
    }
    .section{
        padding: 4rem 0;
    }
    .ardy--building-image-wrapper{
        img{
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 450px;
        }
    }
    
    a{
        font-size: $font-size-16 !important;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px){
    .section-top{
        padding: 8rem 0;
    }

    .section{
        padding: 5rem 0;
    }

    .ardy--building-image-wrapper{
        img{
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 650px;
        }
    }

    a{
        font-size: $font-size-18 !important;
    }
}