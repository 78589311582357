.ardy--course-list-container {
    position: relative;

    .ardy--marketing-list-card {
        @extend .ardy--marketing-bkg-color;

        .ardy--marketing-list-image {
            background-image: url(/src/assets/images/marketing.png);
            @extend .ardy--image-background;
        }
    }

    .ardy--design-list-card {
        @extend .ardy--primary-bkg-color;

        .ardy--design-list-image {
            background-image: url(/src/assets/images/design.png);
            @extend .ardy--image-background;
        }
    }

    .ardy--it-list-card {
        @extend .ardy--emphasis-bkg-color;

        .ardy--it-list-image {
            background-image: url(/src/assets/images/it.png);
            @extend .ardy--image-background;
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 100px;
        height: 2px;
        bottom: -50px;
        left: 150px;
        background-color: $derivatives-bkg-lvl-3;
    }

    .ardy--marketing-list-card,
    .ardy--design-list-card,
    .ardy--it-list-card {
        margin-bottom: -25px;
    }

    .ardy--card-body {
        display: flex;
        align-items: start;
    }

    .ardy--course-card {
        @extend .ardy--primary-box-shadow;
        padding: 1.5rem;
        background-color: $white;
        margin-left: auto;
        border-radius: 8px 0 0 8px;
        margin-bottom: 15px;
        z-index: 1;
        position: relative;
    }
}

@media (min-width: 375px) and (max-width: 767.98px) {
    .ardy--course-list-container {
        .ardy--full-course-card {
            width: 100% !important;
        }

        .ardy--course-card {
            width: calc(100vw - 3rem);
        }

        .ardy--marketing-list-card,
        .ardy--design-list-card,
        .ardy--it-list-card {
            .ardy--card-body {
                // padding: 2.25rem 6rem !important;
                padding: 2.25rem 1.75rem !important;
            }

            .ardy--marketing-list-image,
            .ardy--design-list-image,
            .ardy--it-list-image {
                padding: 4.25rem;
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ardy--course-list-container {
        .ardy--course-card {
            width: calc(100vw - 3rem);

            button {
                width: auto !important;
                min-width: 165px;
            }
        }

        .ardy--marketing-list-card,
        .ardy--design-list-card,
        .ardy--it-list-card {
            .ardy--card-body {
                // padding: 3.25rem 6rem !important;
                padding: 2.25rem 1.75rem !important;
            }

            .ardy--marketing-list-image,
            .ardy--design-list-image,
            .ardy--it-list-image {
                padding: 4.25rem;
            }
        }
    }
}

@media (min-width: 992px) {
    .ardy--course-list-container {
        .ardy--course-card {
            max-width: calc(100vw - 300px);

            button {
                min-width: 200px;
            }

            >div {
                padding-right: 4vw;
            }
        }

        .ardy--marketing-list-card,
        .ardy--design-list-card,
        .ardy--it-list-card {
            display: flex;
            justify-content: space-between;

            .ardy--marketing-list-image,
            .ardy--design-list-image,
            .ardy--it-list-image {
                width: 50%;
            }

            .ardy--card-body {
                padding: 4.25rem 6rem !important;
                max-width: 50%;
            }
        }
    }
}