.ardy--primary-color{
    color: $main-dark;
}
.ardy--primary-bkg-color{
    background-color: $main-dark;
}

.ardy--emphasis-color{
    color: $emphasis;
}
.ardy--emphasis-bkg-color{
    background-color: $emphasis;
}

.ardy--emphasis-pressed-color{
    color: $emphasis-pressed;
}
.ardy--emphasis-pressed-bkg-color{
    background-color: $emphasis-pressed;
}

.ardy--success-color{
    color: $success;
}
.ardy--success-bkg-color{
    background-color: $success;
}

.ardy--attention-color{
    color: $attantion;
}
.ardy--attention-bkg-color{
    background-color: $attantion;
}

.ardy--danger-color{
    color: $alert;
}
.ardy--dander-bkg-color{
    background-color: $alert;
}

.ardy--marketing-color{
    color: $disciplines-marketing;
}
.ardy--marketing-bkg-color{
    background-color: $disciplines-marketing;
}

.ardy--gray-lvl-1{
    color: $derivatives-bkg-lvl-1;   
}
.ardy--gray-bkg-lvl-1{
    background-color: $derivatives-bkg-lvl-1;   
}

.ardy--gray-lvl-2{
    color: $derivatives-bkg-lvl-2;   
}
.ardy--gray-bkg-lvl-2{
    background-color: $derivatives-bkg-lvl-2;   
}

.ardy--gray-lvl-3{
    color: $derivatives-bkg-lvl-3;   
}
.ardy--gray-bkg-lvl-3{
    background-color: $derivatives-bkg-lvl-3;   
}

.ardy--white-bkg{
    background-color: $white;
}

.ardy--link-color{
    color: $emphasis;
}

.ardy--primary-bkg{
    background-color: $ardy--main-background !important;
}

