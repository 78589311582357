$main-dark:#1E2329;
$emphasis:#3965FF;
$ardy--main-background:#E9E9E9;

$disabled:#6F7276;

$derivatives-bkg-lvl-3:#D8D8D8;
$derivatives-bkg-lvl-2:#F4F4F4;
$derivatives-bkg-lvl-1:#FFFFFF;
$emphasis-pressed:#1343EA;

$disciplines-marketing:#E02218;
$disciplines-it:#3229CC;

$success:#169F00;
$attantion:#FF4D00;
$alert:#C10000;
$white: #ffffff;
$black: #000000;

$font-system-mardoto: "Mardoto";
$font-system-suisseint: "Suisse Int";

$font-famely-mardoto: $font-system-mardoto, sans-serif;
$font-famely-suisseint: $font-system-suisseint, sans-serif;
$selected-language: "english";

$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-50: 50px;

$btn-border-radius: 54px;

$selected-language: "Armenian"