.ardy--course-container {
    @media (min-width: 768px) {
        margin-left: 4rem;
        border-radius: 8px 0 0 8px;
    }

    @media (min-width: 375px) and (max-width: 767.98px) {
        // margin-left: 2rem;
        margin-left: 0;
        border-radius: 0;
    }

    .ardy--marketing-card {
        position: relative;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        z-index: 1;
        overflow: hidden;
        @extend .ardy--marketing-color;
        @extend .ardy--primary-box-shadow;

        .ardy--card-body {
            position: relative;

            img {
                position: absolute;
                left: 25px;
                top: 25px;
            }
        }

        // @extend .ardy--marketing-bkg-color;
        .ardy--marketing-image {
            background-image: url(/src/assets/images/marketing-main.png);
            @extend .ardy--image-background;
        }
    }

    .ardy--course-intensive,
    .ardy--course-reguliar {
        margin-left: 3rem;

        & {
            @media (max-width: 991px) {
                margin-left: 0;
            }
        }


        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 50%;
            left: -35px;
            border: 3px solid #D8D8D8;
            transform: translateY(-50%)
        }
    }

    .ardy--course-intensive {
        &::after {
            background-color: #D8D8D8;
            // @extend .ardy--primary-bkg-color;
        }
    }

    .ardy--course-reguliar {
        &::after {
            @extend .ardy--emphasis-bkg-color;
        }
    }

    .ardy--course-card {
        @extend .ardy--primary-box-shadow;
        padding: 1.5rem 0;
        background-color: $white;
        margin-left: auto;
        border-radius: 8px 0 0 8px;
        margin-top: -5px;
        width: 100%;
        position: relative;
    }
}


@media (min-width: 375px) and (max-width: 767.98px) {
    .ardy--course-card {
        >div {
            padding: 0 1rem;
        }
    }

    .ardy--marketing-card,
    .ardy--design-card,
    .ardy--it-card {
        .ardy--card-body {
            padding: 2.25rem 5rem !important;
        }

        .ardy--marketing-image,
        .ardy--design-image,
        .ardy--it-image {
            padding: 4.25rem;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ardy--course-card {
        button {
            width: auto !important;
        }

        >div {
            padding: 0 2.5rem;
        }
    }

    .ardy--marketing-card,
    .ardy--design-card,
    .ardy--it-card {
        .ardy--card-body {
            padding: 3.25rem 6rem !important;
        }

        .ardy--marketing-image,
        .ardy--design-image,
        .ardy--it-image {
            padding: 4.25rem;
        }
    }
}

@media (min-width: 992px) {

    .ardy--marketing-card,
    .ardy--design-card,
    .ardy--it-card {
        display: flex;
        justify-content: space-between;

        .ardy--marketing-image,
        .ardy--design-image,
        .ardy--it-image {
            width: 50%;
        }

        .ardy--card-body {
            padding: 4.25rem 6rem !important;
            max-width: 50%;
        }
    }

    .ardy--course-card {
        >div {
            padding: 0 4rem;
        }
    }
}

.course-lecturer-image {
    @media (max-width: 576px) {
        height: 250px !important;
    }
}