.ardy--login-container{
    // display: none;
    &::after{
        content: '';
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        left: 0;
        background-color: $black;
        opacity: 0.8;
    }
    .ardy--login-wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 10000;
        width: 100%;
        transform: translate(-50%, -50%);
        max-width: 450px;
        background-color: #F4F4F4;
        border-radius: 16px;
        .ardy--login-close{
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
        .ardy--login-body{
            padding: 48px;
        }
    }
    .ardy--login-footer{
        margin-top: 2rem;
        text-align: center;
        p{
            font-size: $font-size-16;
        }
    }
}