.partners-swiper {

  .swiper {
    margin: 0;
    height: 60px;
  }

  .partner-logo {
    height: 120px;
    // margin-right: 60px;

    @media (max-width: 992px) {
      height: 100px;
    }

    // @media (max-width: 768px) {
    //   height: 80px;
    // }
  }
}



// .marquee {
//   // overflow: hidden;
//   height: 65px;
//   position: relative;

//   $duration: 10s;

//   .ticker-wrapper__first-half,
//   .ticker-wrapper__second-half {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     align-items: center;
//     position: absolute;
//     top: 0;
//     right: 0;
//     gap: 40px;
//     animation: ticker $duration infinite linear forwards;
//   }

//   .ticker-wrapper__second-half {
//     animation: $duration ticker $duration/2 infinite linear forwards;
//   }
// }

// @keyframes ticker {
//   0% {
//     transform: translate(100%, 0);
//   }

//   50% {
//     transform: translate(0, 0);
//   }

//   100% {
//     transform: translate(-100%, 0);
//   }
// }


// .marquee {
//   height: 65px;
//   width: 100%;
//   white-space: nowrap;
//   box-sizing: border-box;
//   animation: marquee 10s linear infinite;
//   display: flex;
//   align-items: center;
// }

// @keyframes marquee {
//   0% {
//     transform: translateX(100%);
//   }

//   100% {
//     transform: translateX(-100%);
//   }
// }