.skeleton-block {
  width: 100%;
  padding: 10px;
  // margin-top: 5px;
  background: #E9E9E9;
  border-radius: 3px;

  .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .image,
  h2,
  p {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 3px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-top: 10px;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}