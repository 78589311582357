.ardy--current-language {
    margin: 1.25rem;
    width: 25px;
    height: auto;
    position: relative;

    img {
        width: 25px;
        opacity: 1 !important;
    }

    input {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        opacity: 0;
        cursor: pointer;
    }

    input:checked~.ardy--languages {
        transform: scale(1);
    }
    .ardy--languages {
        padding: 0;
        position: absolute;
        top: 35px;
        transform: scale(0);
        transform-origin: top;
        list-style: none;
        transition: 0.2s;
        background-color: $white;
        padding: 8px;
        border-radius: 5px;
        left: -10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
        z-index: 100;
        li{
            padding: 5px 0;
            a{
                color: $main-dark;
                text-decoration: none;
                font-size: $font-size-14;
            }
        }
        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            transform: rotate(45deg) translateX(-50%);
            background-color: $white;
            top: 0;
            left: 50%;
        }
    }
}
