@font-face {
    font-family: 'Mardoto';
    src: url(/src/fonts/Mardoto/Mardoto-Medium.eot);
    src: url(/src/fonts/Mardoto/Mardoto-Medium.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/Mardoto/Mardoto-Medium.woff2) format('woff2'),
        url(/src/fonts/Mardoto/Mardoto-Medium.woff) format('woff'),
        url(/src/fonts/Mardoto/Mardoto-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mardoto';
    src: url(/src/fonts/Mardoto/Mardoto-Regular.eot);
    src: url(/src/fonts/Mardoto/Mardoto-Regular.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/Mardoto/Mardoto-Regular.woff2) format('woff2'),
        url(/src/fonts/Mardoto/Mardoto-Regular.woff) format('woff'),
        url(/src/fonts/Mardoto/Mardoto-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mardoto';
    src: url(/src/fonts/Mardoto/Mardoto-Light.eot);
    src: url(/src/fonts/Mardoto/Mardoto-Light.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/Mardoto/Mardoto-Light.woff2) format('woff2'),
        url(/src/fonts/Mardoto/Mardoto-Light.woff) format('woff'),
        url(/src/fonts/Mardoto/Mardoto-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

