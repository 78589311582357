.ardy_news_image {
	flex: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: contain;
	height: 450px;

	@media (max-width: 768px) {
		height: 350px;
	}
}

.ardy_news_information {
	flex: 1;
	display: flex;
	gap: 24px;
	flex-direction: column;
	justify-content: center;
	margin-left: 54px;
}

.time {
	color: #3965ff;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	&::first-letter {
		text-transform: uppercase;
	}
	& > div {
		margin-top: 24px;
		width: 94px;
		height: 1px;
		background-color: #1e2329;
	}
}

.title {
	margin: 0;
	padding: 0;
	font-size: 50px;
	font-style: normal;
	font-weight: 300;
	line-height: 60px;
}

.text {
	font-size: 18px;
	font-style: normal;
	font-weight: 450;
	line-height: 28px;
	max-width: 546px;
	width: 100%;
}

.padding_none {
	&.row > * {
		padding-left: 0;
	}
}

.cursor_pointer {
	cursor: pointer !important;
}

.ardy-news-image {
	width: 100%;
	height: 100%;
	max-height: 529px;
	object-fit: cover;
}

.ardy-news-slde-button-cnatiner {
	width: 100%;
	display: flex;
	justify-content: center;
	justify-content: space-between;
	padding: 8px 10px;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	background-color: rgba(30, 35, 41, 0.8);

	& p {
		font-size: 16px;
		font-style: normal;
		font-weight: 450;
		line-height: 26px;
	}
}
.ardy-news-carusel.swiper {
	margin: 0 !important;
	margin-top: -14px !important;
	height: 72px !important;

	& > .swiper-wrapper {
		& > .swiper-slide {
			width: 128px !important;
			height: 72px;
			margin-right: 4px;
			&.swiper-slide > .ardy-ardy-news-carusel-img {
				&:hover::after {
					opacity: 0;
				}
			}
			&.swiper-slide-thumb-active > .ardy-ardy-news-carusel-img {
				border: 2px solid #3965ff;
				&::after {
					opacity: 0;
				}
			}
		}
	}
}
.ardy-ardy-news-carusel-img {
	width: 128px;
	height: 72px;

	border-radius: 4px !important;
	backdrop-filter: sepia(90%);
	transition: all 0.3s;
	position: relative;
	&::after {
		content: "";
		background-color: lightgray;
		border-radius: 4px;
		position: absolute;
		opacity: 0.5;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
	}
}
.ardy-news-information {
	min-width: 316px;
}
.carusel-footer {
	position: absolute;
	bottom: 0;
	padding: 10px 8px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	align-items: center;
	background-color: rgba(30, 35, 41, 0.8);
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	z-index: 10;
	& > p {
		font-size: 16px;
		font-style: normal;
		font-weight: 450;
		line-height: 26px;
		color: white;
		padding: 0;
		margin: 0;
	}
	& > div > button {
		background-color: transparent;
		border: none;
		&:nth-child(1) {
			margin-right: 24px;
		}
	}
}

@media (max-width: 992px) {
	.ardy_news_image {
		margin-top: 10px;
		flex: none;
	}

	.ardy_news_information {
		margin-left: 0;
		flex: none;
		gap: 2vh;
		margin-top: 4vh;
	}

	.padding_none {
		&.row > * {
			padding-left: calc(1.5rem * 0.5);
		}
	}
}
.swiper-slide {
	text-align: center;
	font-size: 18px;
	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}
.first_letter_uppercase::first-letter {
	text-transform: uppercase;
}

.swiper {
	width: 100%;
	margin: 20px 0;
}
