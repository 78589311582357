.carousel-indicators {
    button {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50%;
        background-color: $main-dark !important;

        &.active {
            width: 50px !important;
            border-radius: 10px;
            background-color: $emphasis !important;
        }
    }
}

.mt-8 {
    margin-top: 7rem;
}

.accordion-item {
    .accordion-body {
        >div {
            border-bottom: 1px solid $main-dark;
            padding: 1rem 0;

            &:last-child {
                border-bottom: none;
            }
        }

        .accordion-body-title {
            font-size: $font-size-18;
        }

        .accordion-body-list {
            list-style: none;
            padding-left: 3rem;

            li {
                padding: 0.75rem 0;
            }
        }
    }

    &:last-child {
        .accordion-button {
            border-bottom: 2px solid $disabled;
        }
    }

    .accordion-button {
        background-color: $derivatives-bkg-lvl-3;
        // border-bottom: 2px solid $disabled;
        border-top: 2px solid $disabled;
        @extend .h3;
    }

    .accordion-collapse {
        background-color: $derivatives-bkg-lvl-3;
        padding: 0 3rem;
    }
}

.ardy--faq-container {
    .accordion-collapse {
        padding: 1rem 0 1rem 3rem;

        .accordion-body {
            border-radius: 8px;
            padding: 24px 32px;
        }
    }
}

@media (min-width: 992px) {
    .container-fluid {
        // padding: 0 6rem;
        padding: 0 4rem;
    }

}

@media (max-width: 991.98px) {

    .accordion-collapse {
        padding: 0 !important;
    }
}