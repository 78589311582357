body {
    @if $selected-language=="english" {
        @extend .english-font;
    }

    @else if $selected-language=="armenia" {
        @extend .spanish-font;
    }

    @else if $selected-language=="chinese" {
        @extend .chinese-font;
    }
}

.english-font {
    font-family: $font-famely-suisseint !important;
}

.armenian-font {
    font-family: $font-famely-mardoto !important;
}

//suisseint
.font-suisseint-light {
    @extend .english-font;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.font-suisseint-bold {
    @extend .english-font;
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.font-suisseint-book {
    @extend .english-font;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.font-suisseint-medium {
    @extend .english-font;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.font-suisseint-regular {
    @extend .english-font;
    font-weight: normal;
    font-style: 400;
    font-display: swap;
}

.font-suisseint-semiBold {
    @extend .english-font;
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

//mardoto
.font-mardoto-light {
    @extend .armenian-font;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.font-mardoto-medium {
    @extend .armenian-font;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.font-mardoto-regular {
    @extend .armenian-font;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.ardy--title-wrapper {
    h1 {
        color: $main-dark;
        // word-break: break-word;

        @if $selected-language=="english" {
            @extend .font-suisseint-light;
        }

        @else {
            @extend .font-mardoto-light;
        }
    }

    strong {
        color: $main-dark;
        // word-break: break-word;

        @if $selected-language=="english" {
            @extend .font-suisseint-medium;
        }

        @else {
            @extend .font-mardoto-medium;
        }

        line-height: 60px;
    }

    h2 {
        color: $main-dark;
        // word-break: break-word;

        @if $selected-language=="english" {
            @extend .font-suisseint-light;
        }

        @else {
            @extend .font-mardoto-light;
        }

        line-height: normal;
    }

    h3 {
        color: $main-dark;
        // word-break: break-word;

        @if $selected-language=="english" {
            @extend .font-suisseint-book;
        }

        @else {
            @extend .font-mardoto-medium;
        }

        line-height: normal;
    }
}

.h1 {
    color: $main-dark;
    // word-break: break-word;

    @if $selected-language=="english" {
        @extend .font-suisseint-light;
    }

    @else {
        @extend .font-mardoto-light;
    }
}

.h1-m {
    color: $main-dark;
    // word-break: break-word;

    @if $selected-language=="english" {
        @extend .font-suisseint-medium;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: 60px;
}

.h2 {
    color: $main-dark;
    // word-break: break-word;

    @if $selected-language=="english" {
        @extend .font-suisseint-light;
    }

    @else {
        @extend .font-mardoto-light;
    }

    line-height: normal;
}

.h2-m {
    color: $main-dark;
    // word-break: break-word;

    @if $selected-language=="english" {
        @extend .font-suisseint-medium;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: normal;
}

.h3 {
    color: $main-dark;
    // word-break: break-word;

    @if $selected-language=="english" {
        @extend .font-suisseint-book;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: normal;
}

.max-w-700 {
    max-width: 700px;
}

p {
    font-size: $font-size-16;
    line-height: 26px;

    @if $selected-language=="english" {
        @extend .font-suisseint-regular;
    }

    @else {
        @extend .font-mardoto-regular;
    }

    &.hidden-text {
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 80px;
            background-color: $white;
            position: absolute;
            left: 0;
            bottom: 0;
            background: rgb(233, 233, 233);
            background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(233, 233, 233, 0) 100%);
        }
    }
}

.cta-text {
    color: $main-dark;

    @if $selected-language=="english" {
        @extend .font-suisseint-medium;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: 28px;
}

.btn-text {
    color: $main-dark;

    @if $selected-language=="english" {
        @extend .font-suisseint-regular;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: 28px;
}

.copy-text {
    color: $main-dark;

    @if $selected-language=="english" {
        @extend .font-suisseint-regular;
    }

    @else {
        @extend .font-mardoto-regular;
    }

    line-height: 26px;
}

.hint-text {
    color: $main-dark;

    @if $selected-language=="english" {
        @extend .font-suisseint-medium;
    }

    @else {
        @extend .font-mardoto-medium;
    }

    line-height: 28px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 375px) and (max-width: 767.98px) {
    .ardy--title-wrapper {

        h1,
        strong {
            font-size: $font-size-30;
            line-height: 40px;
        }

        h2,
        .h2-m {
            font-size: $font-size-24;
        }

        h3 {
            font-size: $font-size-20;
        }
    }

    .h1,
    .h1-m {
        font-size: $font-size-30;
        line-height: 40px;
    }

    .h2,
    .h2-m {
        font-size: $font-size-24;
    }

    .h3 {
        font-size: $font-size-20;
    }

    .cta-text,
    .btn-text,
    .copy-text {
        font-size: $font-size-16;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .ardy--title-wrapper {

        h1,
        strong {
            font-size: $font-size-34;
            line-height: 45px;
        }

        h2,
        .h2-m {
            font-size: $font-size-24;
        }

        h3 {
            font-size: $font-size-20;
        }

    }

    .h1,
    .h1-m {
        font-size: $font-size-34;
        line-height: 45px;
    }

    .h2,
    .h2-m {
        font-size: $font-size-24;
    }

    .h3 {
        font-size: $font-size-20;
    }

    .cta-text,
    .btn-text,
    .copy-text {
        font-size: $font-size-16;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .ardy--title-wrapper {

        // h1,
        // strong {
        //     font-size: $font-size-50;
        //     line-height: 55px;
        // }

        h1 {
            font-size: $font-size-50;
            line-height: 55px;
        }

        h2,
        .h2-m {
            font-size: $font-size-32;
        }

        h3 {
            font-size: $font-size-24;
        }
    }

    .h1,
    .h1-m {
        font-size: $font-size-50;
        line-height: 55px;
    }

    .h2,
    .h2-m {
        font-size: $font-size-32;
    }

    .h3 {
        font-size: $font-size-24;
    }

    .cta-text {
        font-size: $font-size-20;
    }

    .btn-text {
        font-size: $font-size-18;
    }

    .copy-text {
        font-size: $font-size-16;
    }

    .hint-text {
        font-size: $font-size-14;
    }
}