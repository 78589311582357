.fz-12 {
	font-size: 12px;
}

.fz-14 {
	font-size: 14px;
}

.fz-16 {
	font-size: 16px;
}

.fz-18 {
	font-size: 18px;
}

.fz-20 {
	font-size: 20px;
}

.fz-22 {
	font-size: 22px;
}

.fz-24 {
	font-size: 24px;
}

.fw-500 {
	font-weight: 500;
}

.fw-400 {
	font-weight: 400;
}

.py-70 {
	padding-top: 70px;
	padding-bottom: 70px;
}

.ardy--custom-carousel {
	.slider-control-bottomcenter {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		ul {
			top: 33px !important;

			.paging-item {
				button {
					svg {
						height: 6px;
						width: 6px;
						margin: 2px;
					}
				}
			}

			.active {
				button {
					svg {
						fill: $emphasis;
						width: 45px;
						background-color: $emphasis;
						border-radius: 5px;
					}
				}
			}
		}
	}
}

.ardy--standart-carousel {
	.slider-control-bottomcenter {
		width: 100%;
		display: flex;
		justify-content: center;

		ul {
			.paging-item {
				button {
					svg {
						height: 6px;
						width: 6px;
						margin: 2px;
					}
				}
			}

			.active {
				button {
					svg {
						fill: $emphasis;
						width: 45px;
						background-color: $emphasis;
						border-radius: 5px;
					}
				}
			}
		}
	}
}

@media (min-width: 375px) and (max-width: 767.98px) {
	.ardy--custom-carousel {
		padding-bottom: 48px;
	}

	.slider-control-bottomcenter {
		justify-content: center !important;
	}
}

.ardy--text-white-wrapper {
	* {
		color: $white !important;
	}
}

.word-break-break-all {
	word-break: break-all;
}

.word-break-break-word {
	word-break: break-word;
}

.max-line-1 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.max-line-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.max-line-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.max-line-4 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.max-line-5 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}

.cursor-pointer {
	cursor: pointer;
}

.Toastify__toast {
	border-radius: 15px;
}

.ardy--custom-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;

	svg {
		width: 45px;
	}
}

.Toastify__toast-container {
	z-index: 10000;
}

.warning-svg {
	path {
		stroke: $disciplines-marketing;
	}
}

.success-svg {
	path {
		stroke: $success;
	}
}

.fixed-center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.syllabus-accordion-item {
	.accordion-collapse {
		padding-left: 0 !important;
	}

	button {
		color: $main-dark;
		font-weight: 500 !important;
		line-height: normal;
		font-size: 24px !important;
		padding: 27px 10px 27px 0;
	}
}

.faq-accordion-item-transparent {
	background-color: transparent;

	.accordion-collapse {
		padding-left: 0 !important;
	}

	* {
		background: transparent !important;

		button {
			color: $main-dark;
			font-weight: 700 !important;
			line-height: 22px;
			font-size: 16px !important;
			padding: 27px 10px 27px 0;
		}
	}
}

.faq-more {
	color: $main-dark;
	font-weight: 500;
	line-height: 28px;
	cursor: pointer;
}

.benefits-block {
	background-color: $derivatives-bkg-lvl-2;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	padding: 64px 64px 64px 30px;
	z-index: -1;
	position: relative;
	right: -52px;

	@media (max-width: 992px) {
		right: 0;
	}
}

.show-more-less-clickable {
	display: block;
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	text-decoration: none !important;
}

.pointer-events-none {
	pointer-events: none;
}

.empty-accordion {
	pointer-events: none;

	h2 {
		button {
			&::after {
				display: none;
			}
		}
	}
}

.ardy--paddingX-block {
	padding: 0 5rem;

	@media (min-width: 375px) and (max-width: 992px) {
		padding: 0 2rem;
	}
}

.ardy--padding-right-block {
	padding-right: 7rem;
	padding-left: 2rem;

	@media (min-width: 375px) and (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.ardy--choose-btn {
	@media (max-width: 992px) {
		width: 100% !important;
	}
}

.header {
	transition: transform 1s ease;
}

.fixed-header {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: $ardy--main-background;
	z-index: 999;
	animation: slide-top 0.2s ease-in both;

	.navbar-brand {
		img {
			max-width: 101px;
		}
	}
}

@keyframes slide-top {
	0% {
		transform: translateY(-100px);
	}

	100% {
		transform: translateY(0);
	}
}

.fixed-header.shadow {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-slide-current {
	.slide-visible {
		margin: 0 16px !important;
	}
}

.without-checkbox {
	&::after {
		display: none !important;
	}
}

.disabled-btn {
	opacity: .4;
	pointer-events: none;
}