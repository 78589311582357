.ardy--aler-container {
    display: flex;
    align-items: center;
    padding: 32px 64px;
    position: fixed;
    width: 100%;
    z-index: 10000;
    justify-content: space-between;
    background-color: $main-dark;
    bottom: -100%;
    transition: 0.3s;
    border-radius: 16px 16px 0px 0px;

    p {
        color: $white;
        margin: 0;
    }

    &.active {
        bottom: 0;
    }
}

.ardy--alert {
    .ardy--alert-line {
        position: absolute;
        width: 12px;
        height: 100%;
        left: 0;
        top: 0;
    }

    .ardy--alert-error-line {
        background-color: $disciplines-marketing;
    }

    .ardy--alert-success-line {
        background-color: $success;
    }
}