.navbar {
    .navbar-toggler {
        border: none;
    }

    .navbar-brand {
        img {
            max-width: 181px;

            @media (max-width: 991.98px) {
                max-width: 136px;
            }
        }
    }

    .navbar-nav {
        position: relative;

        @media (min-width: 375px) and (max-width: 991.98px) {
            align-items: start;
            padding: 1rem 0;
            padding-bottom: 4rem;

            .nav-item {
                padding: 0.5rem 1rem;
                width: 100%;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-bottom: 1px solid $derivatives-bkg-lvl-3;
                }

                &:last-child {
                    position: absolute;
                    width: fit-content;
                    bottom: 0;
                    right: 0;

                    .ardy--mobile-current-language {
                        margin: 0;

                        .ardy--mobile-languages {
                            display: flex;
                            list-style: none;

                            li {
                                font-weight: 500;
                                border-radius: 5px;
                                border: 2px solid $main-dark;
                                padding: 0.5rem 1rem;
                                margin-left: 10px;

                                &.active {
                                    border-color: $emphasis-pressed !important;

                                    a {
                                        color: $emphasis-pressed;
                                    }
                                }
                            }
                        }
                    }
                }

                .nav-link {
                    width: fit-content;
                    padding: 0.5rem 1rem;

                    &::after {
                        right: 0px;
                        top: 0px;
                    }
                }
            }
        }

        @media (min-width: 992px) {
            align-items: center;
        }
    }
}

.nav-item {
    .nav-link {
        @if $selected-language=="english" {
            @extend .font-suisseint-regular;
        }

        @else {
            @extend .font-mardoto-regular;
        }

        &.active {
            position: relative;
            color: $emphasis;

            &::after {
                content: url(/src/assets/icons/chevron.svg);
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
    }

    .btn-group {
        padding: 0.925rem;

        .dropdown--btn {
            &::before {
                border: none;
            }
        }

        .dropdown-menu {
            top: auto;
            right: 50%;
            transform: translateX(-50%);
        }
    }

    @media (min-width: 992px) {
        .nav-link {
            padding: 1.25rem !important;
        }
    }
}