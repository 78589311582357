.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $black;
  z-index: 10001;
}

.scale-down-ver-bottom {
  // -webkit-animation: scale-down-ver-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
  animation: scale-down-ver-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: calc(100vh / 2);
  background-color: $black;
  z-index: 10002;
}

.scale-down-ver-top {
  //  -webkit-animation: scale-down-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-down-ver-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100vh / 2);
  background-color: $black;
  z-index: 10002;
}

@-webkit-keyframes scale-down-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

@keyframes scale-down-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

@-webkit-keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.loader-line-block {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  
  
  img {
    max-width: 180px;
    @media (min-width: 375px) and (max-width: 767.98px) {
      max-width: 160px;
    }
  }
}

.loader-line {
  width: 100%;
  height: 3px;
  position: fixed;
  overflow: hidden;
  background-color: darken($color: $ardy--main-background, $amount: 50);
  z-index: 10003;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 100%;
  width: 40%;
  background-color: $ardy--main-background;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}