.cover-decoration {
    position: absolute;
    width: calc(100vw - 200px);
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;

    max-height: 100vh;
}

.cover-profile-decoration {
    position: fixed;
    z-index: -1;
}

.cover-ardy--section-image-wrapper {

    img,
    video {
        border-radius: 10px;
    }

    img {
        width: 100%;
    }

    video {
        width: 100% !important;
    }
}

.ardy--custom-section-video-player {
    position: relative;
    display: flex;
    justify-content: end;

    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.ardy--custom-course-video-player {
    width: 100% !important;
    height: 100% !important;

    .ardy--custom-course-video-poster{
        max-height: 400px;
    }

    video {
        display: block;
        border-radius: 5px;
    }
}

@media (min-width: 768px) {

    // .cover-ardy--section-image-wrapper,
    .slider-wrapper {
        position: relative;
        // right: -96px;
        // right: -4rem;
        margin-left: 4rem;
        // top: -50%;
        // width: 100%;

        .carousel-item{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 468px;
        }
        img {
            width: 100%;
        }
    }

    .cover-profile-decoration {
        top: -250px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 375px) and (max-width: 767.98px) {
    .cover-profile-decoration {
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
        width: 800px;
    }
    .slider-wrapper {
        // padding: 0 0 0 2rem;
        padding: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    // .cover-ardy--section-image-wrapper,
    .slider-wrapper {
        position: relative;
        // right: -12px;
        right: 4rem;
        padding: 0 0 0 4rem;
        top: -50%;
        width: 100%;

        img {
            width: 100%;
        }
    }
}