.service-decoration{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;
    img{
        position: absolute;
        width: calc(100vw - 200px);
        left: 50%;
        transform: translateX(-20%);
        top: -3rem;
        z-index: -1;
    }

}

.service-section{
    .row{
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 55%;
            width: 75px;
            height: 1px;
            background-color: $derivatives-bkg-lvl-3;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75px;
            height: 1px;
            background-color: $derivatives-bkg-lvl-3;
        }
    }
}

.service-container{
    position: relative;
    .service-image{
        position: absolute;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 1px;
            height: 75px;
            background-color: $main-dark;
        }
        &.extra-line{
            &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 150%);
            width: 1px;
            height: 75px;
            background-color: $derivatives-bkg-lvl-3;
        }
        }
    }
    @media (min-width: 768px){
        .service-info-wrapper{
            padding: 3rem 5rem;
        }
    }

    @media (min-width: 375px) and (max-width: 767.98px){
        .service-info-wrapper{
            padding: 2rem 4rem;
        }
    }
}