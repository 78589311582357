@font-face {
    font-family: 'Suisse Intl';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Regular.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Regular.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Regular.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Regular.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Light.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Light.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Light.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Light.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Bold.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Bold.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Bold.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Bold.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Book.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Book.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Book.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Book.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Book.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Medium.eot);
    src: url(/src/fonts/SuisseIntl/SuisseIntl-Medium.eot?#iefix) format('embedded-opentype'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Medium.woff2) format('woff2'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Medium.woff) format('woff'),
        url(/src/fonts/SuisseIntl/SuisseIntl-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

