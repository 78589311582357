.footer {
	margin-top: 4rem;
	border-bottom-left-radius: 0px !important;
	@extend .ardy--side-section;

	.footer-items-wrapper {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			width: 100%;

			@media (min-width: 768px) {
				padding-right: 4rem;
			}

			@media (min-width: 375px) and (max-width: 767.98px) {
				&:first-child {
					padding-right: 2rem;
				}
			}

			li {
				display: flex;
				color: $white;
				padding: 1.5rem 0;
				border-bottom: 1px solid $disabled;

				a {
					font-size: $font-size-18;
					color: $white;
					display: block;
					text-decoration: none;
				}
			}
		}
	}

	.social-media-wrapper {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding-right: 2rem;

				a {
					display: block;

					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.map.footer {
	border-bottom-left-radius: 16px !important;

	@media (min-width: 375px) and (max-width: 767.98px) {
		margin-left: 0;
		border-radius: 0 !important;

		.ymaps-2-1-79-map {
			border-radius: 0;
		}
	}
}

.ardy_footer_logo {
	@media (min-width: 375px) and (max-width: 767.98px) {
		width: 120px;
	}
}

.ymaps-2-1-79-map {
	overflow: hidden;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
