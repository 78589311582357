@import "app";

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
.text_error {
	color: $disciplines-marketing !important;
}
.border_error {
	border-color: $disciplines-marketing !important;
	border-width: 2px !important;
}
