.ardy--user-avatar-xl {
  border-radius: 50%;
  width: 116px;
  height: 116px;
}

.button,
.label--btn,
.link--btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 54px;
  font-size: 20px;
  gap: 8px;
  border: none;
  padding: 13px 26px;
  transition: 0.32s;
}

.header--btn {
  padding: 8px 32px;
  font-size: 18px;
}

.arrow--btn img {
  transition: 0.3s;
  margin-left: 20px;
}
.arrow--btn:hover img {
  margin-left: 30px;
}

.link--btn {
  text-decoration: none;
}

.label--btn {
  background-color: transparent;
  font-size: 18px !important;
}
.label--btn:hover {
  text-decoration: underline;
}

.dropdown--btn {
  border: none;
  background-color: transparent;
}

.primary--btn {
  background-color: #1E2329;
  color: #ffffff;
}

.primary--btn.disabled--btn {
  background-color: #6F7276 !important;
}

.primary-outline--btn {
  background-color: #ffffff !important;
  border: 2px solid #1E2329;
}
.primary-outline--btn:hover {
  background-color: #1E2329 !important;
  color: #ffffff;
}

.emphasys-outline--btn {
  background-color: #ffffff !important;
  border: 2px solid #3965FF;
}
.emphasys-outline--btn:hover {
  background-color: #3965FF !important;
  color: #ffffff;
}

.emphasys-pressed-outline--btn {
  background-color: #ffffff !important;
  border: 2px solid #1343EA;
}
.emphasys-pressed-outline--btn:hover {
  background-color: #1343EA !important;
  color: #ffffff;
}

.disabled-outline--btn {
  background-color: #ffffff !important;
  border: 2px solid #6F7276;
}

.white-outline--btn {
  background-color: transparent !important;
  border: 2px solid #ffffff;
  color: #ffffff;
}
.white-outline--btn:hover {
  background-color: #ffffff !important;
  color: #1E2329;
}

.primary-label--btn {
  color: #1E2329;
}

.emphasys-label--btn {
  color: #3965FF !important;
}

.emphasys-pressed-label--btn {
  color: #1343EA !important;
}

.disabled-label--btn {
  color: #6F7276 !important;
}

.gray-label--btn {
  color: #E9E9E9 !important;
}

.circal--btn {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: 0px 8px 20px 0px rgba(48, 72, 158, 0.4);
}

.segmented--btn {
  background-color: #D8D8D8;
  border-radius: 54px;
}
.segmented--btn .button {
  background-color: transparent;
}
.segmented--btn .button.active {
  background-color: #1E2329;
  color: #ffffff;
}

@media (min-width: 375px) and (max-width: 991.98px) {
  .button,
  .label--btn,
  .link--btn {
    width: 100%;
    font-size: 16px;
  }
  .segmented--btn .button {
    padding: 13px 20px;
  }
  .primary--btn:hover:not(.disabled--btn) {
    background-color: #1343EA;
  }
}
@media (min-width: 992px) {
  .button,
  .label--btn,
  .link--btn {
    font-size: 20px;
  }
  .primary--btn:hover:not(.disabled--btn) {
    background-color: #3965FF;
    box-shadow: 0px 8px 20px 0px rgba(30, 35, 41, 0.4);
  }
  .segmented--btn .button {
    padding: 13px 46px;
  }
}
.ardy--card {
  border-radius: 20px;
  overflow: hidden;
  background-color: #ffffff;
}
.ardy--card .ardy--card-image {
  width: 100%;
  height: 165px;
}
.ardy--card .ardy--card-body {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}
.ardy--card .ardy--card-body .ardy--vertical-date {
  color: #3965FF;
  font-size: 24px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}
.ardy--card .ardy--card-body .ardy--vertical-date span {
  display: block;
}
.ardy--card .ardy--card-body .ardy--vertical-date span:nth-child(1) {
  line-height: 35px;
  font-weight: 500;
}
.ardy--card .ardy--card-body .ardy--vertical-date span:nth-child(3) {
  font-size: 16px;
}
.ardy--card .ardy--card-body .ardy--vertical-date span:nth-child(2) {
  width: 100%;
  height: 1px;
  background-color: #3965FF;
}

.ardy--course-topic-card.ardy-course-image-card .ardy--course-card-body .ardy--course-card-info {
  position: absolute;
}

.ardy--course-topic-card {
  position: relative;
  margin-left: 25px;
}
.ardy--course-topic-card .ardy--course-card-title {
  position: absolute;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left;
  left: -15px;
  padding: 0;
  top: 0;
  z-index: 1;
  text-align: end;
}
.ardy--course-topic-card .ardy--course-card-title::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #77889c;
  top: -2px;
  right: -10px;
}
.ardy--course-topic-card .ardy--course-card-title h4 {
  font-size: 14px;
}
.ardy--course-topic-card .ardy--course-card-body {
  position: relative;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info {
  background-color: #F4F4F4;
  padding: 32px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  justify-content: space-between;
}
.ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info .ardy--course-body-card-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info .ardy--course-card-text {
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 450;
  line-height: 26px;
  font-weight: normal;
}
.ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info a {
  margin-left: auto;
  display: block;
}
.ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info a img {
  width: 18px;
}

@media (min-width: 834px) {
  .ardy-course-image-card .ardy--course-card-body {
    min-height: 350px;
  }
  .ardy-course-image-card .ardy--course-card-body .ardy--course-card-info {
    width: calc(25vw - 30px);
  }
  .ardy--course-card-body .ardy--course-card-info {
    bottom: -20px;
    right: 0;
    min-height: 250px;
  }
  .ardy--course-card-body .ardy--course-card-info a .ardy--arrow-hover {
    display: none;
    opacity: 0;
  }
  .ardy--course-card-body .ardy--course-card-info:hover {
    box-shadow: 0px 8px 20px 0px rgba(30, 35, 41, 0.4);
  }
  .ardy--course-card-body .ardy--course-card-info:hover .ardy--arrow-hover {
    display: block;
    opacity: 1;
  }
  .ardy--course-card-body .ardy--course-card-info:hover img:not(.ardy--arrow-hover) {
    display: none;
  }
}
@media (max-width: 833.98px) {
  .ardy-course-image-card .ardy--course-card-body {
    min-height: 450px;
  }
  .ardy--course-card-body .ardy--course-card-info {
    bottom: 0;
    width: 100%;
    box-shadow: 0px 8px 20px 0px rgba(30, 35, 41, 0.4);
  }
  .ardy--course-card-body .ardy--course-card-info .ardy--arrow-hover {
    display: block;
    opacity: 1;
  }
  .ardy--course-card-body .ardy--course-card-info img:not(.ardy--arrow-hover) {
    display: none;
  }
}
@media (max-width: 575px) {
  .ardy--course-card-text {
    display: none !important;
  }
}
.ardy--underline {
  max-width: 100px;
  border-top: 2.5px solid #000000;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=tel],
textarea {
  border-radius: 8px;
  border: 2px solid #D8D8D8;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.ardy--icon-input {
  position: relative;
}
.ardy--icon-input img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 10px;
  cursor: pointer;
  opacity: 0.7;
}
.ardy--icon-input input {
  padding-right: 55px !important;
}

@media (min-width: 375px) and (max-width: 991.98px) {
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=number],
  input[type=tel],
  textarea {
    padding: 8px 16px;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=number],
  input[type=tel],
  textarea {
    padding: 14px 16px;
    font-size: 18px;
  }
}
.navbar .navbar-toggler {
  border: none;
}
.navbar .navbar-brand img {
  max-width: 181px;
}
@media (max-width: 991.98px) {
  .navbar .navbar-brand img {
    max-width: 136px;
  }
}
.navbar .navbar-nav {
  position: relative;
}
@media (min-width: 375px) and (max-width: 991.98px) {
  .navbar .navbar-nav {
    align-items: start;
    padding: 1rem 0;
    padding-bottom: 4rem;
  }
  .navbar .navbar-nav .nav-item {
    padding: 0.5rem 1rem;
    width: 100%;
  }
  .navbar .navbar-nav .nav-item:nth-child(1), .navbar .navbar-nav .nav-item:nth-child(2), .navbar .navbar-nav .nav-item:nth-child(3) {
    border-bottom: 1px solid #D8D8D8;
  }
  .navbar .navbar-nav .nav-item:last-child {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    bottom: 0;
    right: 0;
  }
  .navbar .navbar-nav .nav-item:last-child .ardy--mobile-current-language {
    margin: 0;
  }
  .navbar .navbar-nav .nav-item:last-child .ardy--mobile-current-language .ardy--mobile-languages {
    display: flex;
    list-style: none;
  }
  .navbar .navbar-nav .nav-item:last-child .ardy--mobile-current-language .ardy--mobile-languages li {
    font-weight: 500;
    border-radius: 5px;
    border: 2px solid #1E2329;
    padding: 0.5rem 1rem;
    margin-left: 10px;
  }
  .navbar .navbar-nav .nav-item:last-child .ardy--mobile-current-language .ardy--mobile-languages li.active {
    border-color: #1343EA !important;
  }
  .navbar .navbar-nav .nav-item:last-child .ardy--mobile-current-language .ardy--mobile-languages li.active a {
    color: #1343EA;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.5rem 1rem;
  }
  .navbar .navbar-nav .nav-item .nav-link::after {
    right: 0px;
    top: 0px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav {
    align-items: center;
  }
}

.nav-item .nav-link.active {
  position: relative;
  color: #3965FF;
}
.nav-item .nav-link.active::after {
  content: url(/src/assets/icons/chevron.svg);
  position: absolute;
  right: 10px;
  top: 10px;
}
.nav-item .btn-group {
  padding: 0.925rem;
}
.nav-item .btn-group .dropdown--btn::before {
  border: none;
}
.nav-item .btn-group .dropdown-menu {
  top: auto;
  right: 50%;
  transform: translateX(-50%);
}
@media (min-width: 992px) {
  .nav-item .nav-link {
    padding: 1.25rem !important;
  }
}

.english-font, .font-suisseint-semiBold, .font-suisseint-regular, .font-suisseint-medium, .font-suisseint-book, .font-suisseint-bold, .font-suisseint-light {
  font-family: "Suisse Int", sans-serif !important;
}

.armenian-font, .font-mardoto-regular, .copy-text, p, .button,
.label--btn,
.link--btn, input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=tel],
textarea, .nav-item .nav-link, .font-mardoto-medium, .hint-text, .btn-text, .cta-text, .h3, .accordion-item .accordion-button, .h2-m, .h1-m, .ardy--title-wrapper h3, .ardy--title-wrapper strong, .font-mardoto-light, .h2, .h1, .ardy--title-wrapper h2, .ardy--title-wrapper h1 {
  font-family: "Mardoto", sans-serif !important;
}

.font-suisseint-light {
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.font-suisseint-bold {
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.font-suisseint-book {
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.font-suisseint-medium {
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.font-suisseint-regular {
  font-weight: normal;
  font-style: 400;
  font-display: swap;
}

.font-suisseint-semiBold {
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.font-mardoto-light, .h2, .h1, .ardy--title-wrapper h2, .ardy--title-wrapper h1 {
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.font-mardoto-medium, .hint-text, .btn-text, .cta-text, .h3, .accordion-item .accordion-button, .h2-m, .h1-m, .ardy--title-wrapper h3, .ardy--title-wrapper strong {
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.font-mardoto-regular, .copy-text, p, .button,
.label--btn,
.link--btn, input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=tel],
textarea, .nav-item .nav-link {
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.ardy--title-wrapper h1 {
  color: #1E2329;
}
.ardy--title-wrapper strong {
  color: #1E2329;
  line-height: 60px;
}
.ardy--title-wrapper h2 {
  color: #1E2329;
  line-height: normal;
}
.ardy--title-wrapper h3 {
  color: #1E2329;
  line-height: normal;
}

.h1 {
  color: #1E2329;
}

.h1-m {
  color: #1E2329;
  line-height: 60px;
}

.h2 {
  color: #1E2329;
  line-height: normal;
}

.h2-m {
  color: #1E2329;
  line-height: normal;
}

.h3, .accordion-item .accordion-button {
  color: #1E2329;
  line-height: normal;
}

.max-w-700 {
  max-width: 700px;
}

p {
  font-size: 16px;
  line-height: 26px;
}
p.hidden-text {
  position: relative;
}
p.hidden-text::after {
  content: "";
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(233, 233, 233);
  background: linear-gradient(0deg, rgb(233, 233, 233) 0%, rgba(233, 233, 233, 0) 100%);
}

.cta-text {
  color: #1E2329;
  line-height: 28px;
}

.btn-text {
  color: #1E2329;
  line-height: 28px;
}

.copy-text {
  color: #1E2329;
  line-height: 26px;
}

.hint-text {
  color: #1E2329;
  line-height: 28px;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--title-wrapper h1,
  .ardy--title-wrapper strong {
    font-size: 30px;
    line-height: 40px;
  }
  .ardy--title-wrapper h2,
  .ardy--title-wrapper .h2-m {
    font-size: 24px;
  }
  .ardy--title-wrapper h3 {
    font-size: 20px;
  }
  .h1,
  .h1-m {
    font-size: 30px;
    line-height: 40px;
  }
  .h2,
  .h2-m {
    font-size: 24px;
  }
  .h3, .accordion-item .accordion-button {
    font-size: 20px;
  }
  .cta-text,
  .btn-text,
  .copy-text {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ardy--title-wrapper h1,
  .ardy--title-wrapper strong {
    font-size: 34px;
    line-height: 45px;
  }
  .ardy--title-wrapper h2,
  .ardy--title-wrapper .h2-m {
    font-size: 24px;
  }
  .ardy--title-wrapper h3 {
    font-size: 20px;
  }
  .h1,
  .h1-m {
    font-size: 34px;
    line-height: 45px;
  }
  .h2,
  .h2-m {
    font-size: 24px;
  }
  .h3, .accordion-item .accordion-button {
    font-size: 20px;
  }
  .cta-text,
  .btn-text,
  .copy-text {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .ardy--title-wrapper h1 {
    font-size: 50px;
    line-height: 55px;
  }
  .ardy--title-wrapper h2,
  .ardy--title-wrapper .h2-m {
    font-size: 32px;
  }
  .ardy--title-wrapper h3 {
    font-size: 24px;
  }
  .h1,
  .h1-m {
    font-size: 50px;
    line-height: 55px;
  }
  .h2,
  .h2-m {
    font-size: 32px;
  }
  .h3, .accordion-item .accordion-button {
    font-size: 24px;
  }
  .cta-text {
    font-size: 20px;
  }
  .btn-text {
    font-size: 18px;
  }
  .copy-text {
    font-size: 16px;
  }
  .hint-text {
    font-size: 14px;
  }
}
.ardy--current-language {
  margin: 1.25rem;
  width: 25px;
  height: auto;
  position: relative;
}
.ardy--current-language img {
  width: 25px;
  opacity: 1 !important;
}
.ardy--current-language input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}
.ardy--current-language input:checked ~ .ardy--languages {
  transform: scale(1);
}
.ardy--current-language .ardy--languages {
  padding: 0;
  position: absolute;
  top: 35px;
  transform: scale(0);
  transform-origin: top;
  list-style: none;
  transition: 0.2s;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 5px;
  left: -10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  z-index: 100;
}
.ardy--current-language .ardy--languages li {
  padding: 5px 0;
}
.ardy--current-language .ardy--languages li a {
  color: #1E2329;
  text-decoration: none;
  font-size: 14px;
}
.ardy--current-language .ardy--languages::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateX(-50%);
  background-color: #ffffff;
  top: 0;
  left: 50%;
}

.service-decoration {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.service-decoration img {
  position: absolute;
  width: calc(100vw - 200px);
  left: 50%;
  transform: translateX(-20%);
  top: -3rem;
  z-index: -1;
}

.service-section .row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 55%;
  width: 75px;
  height: 1px;
  background-color: #D8D8D8;
}
.service-section .row::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 75px;
  height: 1px;
  background-color: #D8D8D8;
}

.service-container {
  position: relative;
}
.service-container .service-image {
  position: absolute;
}
.service-container .service-image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 1px;
  height: 75px;
  background-color: #1E2329;
}
.service-container .service-image.extra-line::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 150%);
  width: 1px;
  height: 75px;
  background-color: #D8D8D8;
}
@media (min-width: 768px) {
  .service-container .service-info-wrapper {
    padding: 3rem 5rem;
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .service-container .service-info-wrapper {
    padding: 2rem 4rem;
  }
}

.slider-wrapper {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
}
.slider-wrapper img {
  width: 100%;
}
.slider-wrapper .carousel-item {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 468px;
}

.ardy--slider-section {
  border-radius: 16px;
  position: relative;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--slider-section {
    padding: 28px;
    border-radius: 16px;
    position: relative;
  }
  .carousel-inner {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 833.98px) {
  .ardy--slider-section {
    padding: 28px;
    border-radius: 16px;
    position: relative;
  }
  .ardy--slider-section .carousel-indicators {
    margin: 0 28px 28px 0;
    bottom: 0;
    right: 0;
    width: -moz-fit-content;
    width: fit-content;
    left: auto;
  }
}
@media (min-width: 834px) {
  .ardy--slider-section {
    padding: 48px;
    border-radius: 16px;
    position: relative;
  }
  .ardy--slider-section .carousel-indicators {
    margin: 0 48px 15px 0;
    bottom: 0;
    right: 0;
    width: -moz-fit-content;
    width: fit-content;
    left: auto;
  }
}
.ardy--primary-color {
  color: #1E2329;
}

.ardy--primary-bkg-color, .ardy--course-list-container .ardy--design-list-card {
  background-color: #1E2329;
}

.ardy--emphasis-color {
  color: #3965FF;
}

.ardy--emphasis-bkg-color, .ardy--course-list-container .ardy--it-list-card, .ardy--course-container .ardy--course-reguliar::after {
  background-color: #3965FF;
}

.ardy--emphasis-pressed-color {
  color: #1343EA;
}

.ardy--emphasis-pressed-bkg-color {
  background-color: #1343EA;
}

.ardy--success-color {
  color: #169F00;
}

.ardy--success-bkg-color {
  background-color: #169F00;
}

.ardy--attention-color {
  color: #FF4D00;
}

.ardy--attention-bkg-color {
  background-color: #FF4D00;
}

.ardy--danger-color {
  color: #C10000;
}

.ardy--dander-bkg-color {
  background-color: #C10000;
}

.ardy--marketing-color, .ardy--course-container .ardy--marketing-card {
  color: #E02218;
}

.ardy--marketing-bkg-color, .ardy--course-list-container .ardy--marketing-list-card {
  background-color: #E02218;
}

.ardy--gray-lvl-1 {
  color: #FFFFFF;
}

.ardy--gray-bkg-lvl-1 {
  background-color: #FFFFFF;
}

.ardy--gray-lvl-2 {
  color: #F4F4F4;
}

.ardy--gray-bkg-lvl-2 {
  background-color: #F4F4F4;
}

.ardy--gray-lvl-3 {
  color: #D8D8D8;
}

.ardy--gray-bkg-lvl-3, .ardy--slider-section {
  background-color: #D8D8D8;
}

.ardy--white-bkg {
  background-color: #ffffff;
}

.ardy--link-color {
  color: #3965FF;
}

.ardy--primary-bkg {
  background-color: #E9E9E9 !important;
}

.ardy_news_image {
  flex: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -o-object-fit: contain;
     object-fit: contain;
  height: 450px;
}
@media (max-width: 768px) {
  .ardy_news_image {
    height: 350px;
  }
}

.ardy_news_information {
  flex: 1;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  margin-left: 54px;
}

.time {
  color: #3965ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.time::first-letter {
  text-transform: uppercase;
}
.time > div {
  margin-top: 24px;
  width: 94px;
  height: 1px;
  background-color: #1e2329;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 28px;
  max-width: 546px;
  width: 100%;
}

.padding_none.row > * {
  padding-left: 0;
}

.cursor_pointer {
  cursor: pointer !important;
}

.ardy-news-image {
  width: 100%;
  height: 100%;
  max-height: 529px;
  -o-object-fit: cover;
     object-fit: cover;
}

.ardy-news-slde-button-cnatiner {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: rgba(30, 35, 41, 0.8);
}
.ardy-news-slde-button-cnatiner p {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 26px;
}

.ardy-news-carusel.swiper {
  margin: 0 !important;
  margin-top: -14px !important;
  height: 72px !important;
}
.ardy-news-carusel.swiper > .swiper-wrapper > .swiper-slide {
  width: 128px !important;
  height: 72px;
  margin-right: 4px;
}
.ardy-news-carusel.swiper > .swiper-wrapper > .swiper-slide.swiper-slide > .ardy-ardy-news-carusel-img:hover::after {
  opacity: 0;
}
.ardy-news-carusel.swiper > .swiper-wrapper > .swiper-slide.swiper-slide-thumb-active > .ardy-ardy-news-carusel-img {
  border: 2px solid #3965ff;
}
.ardy-news-carusel.swiper > .swiper-wrapper > .swiper-slide.swiper-slide-thumb-active > .ardy-ardy-news-carusel-img::after {
  opacity: 0;
}

.ardy-ardy-news-carusel-img {
  width: 128px;
  height: 72px;
  border-radius: 4px !important;
  -webkit-backdrop-filter: sepia(90%);
          backdrop-filter: sepia(90%);
  transition: all 0.3s;
  position: relative;
}
.ardy-ardy-news-carusel-img::after {
  content: "";
  background-color: lightgray;
  border-radius: 4px;
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.ardy-news-information {
  min-width: 316px;
}

.carusel-footer {
  position: absolute;
  bottom: 0;
  padding: 10px 8px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  background-color: rgba(30, 35, 41, 0.8);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  z-index: 10;
}
.carusel-footer > p {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 26px;
  color: white;
  padding: 0;
  margin: 0;
}
.carusel-footer > div > button {
  background-color: transparent;
  border: none;
}
.carusel-footer > div > button:nth-child(1) {
  margin-right: 24px;
}

@media (max-width: 992px) {
  .ardy_news_image {
    margin-top: 10px;
    flex: none;
  }
  .ardy_news_information {
    margin-left: 0;
    flex: none;
    gap: 2vh;
    margin-top: 4vh;
  }
  .padding_none.row > * {
    padding-left: 0.75rem;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.first_letter_uppercase::first-letter {
  text-transform: uppercase;
}

.swiper {
  width: 100%;
  margin: 20px 0;
}

.ardy-event-carousel-img {
  width: 217px;
  height: 130px;
  -o-object-fit: contain;
     object-fit: contain;
  min-width: 100px;
  mix-blend-mode: multiply;
}

@media (max-width: 1200px) {
  .ardy-event-carousel-img {
    width: 11vw;
  }
}
.footer {
  margin-top: 4rem;
  border-bottom-left-radius: 0px !important;
}
.footer .footer-items-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
@media (min-width: 768px) {
  .footer .footer-items-wrapper ul {
    padding-right: 4rem;
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .footer .footer-items-wrapper ul:first-child {
    padding-right: 2rem;
  }
}
.footer .footer-items-wrapper ul li {
  display: flex;
  color: #ffffff;
  padding: 1.5rem 0;
  border-bottom: 1px solid #6F7276;
}
.footer .footer-items-wrapper ul li a {
  font-size: 18px;
  color: #ffffff;
  display: block;
  text-decoration: none;
}
.footer .social-media-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer .social-media-wrapper ul li {
  padding-right: 2rem;
}
.footer .social-media-wrapper ul li a {
  display: block;
}
.footer .social-media-wrapper ul li a img {
  width: 100%;
}

.map.footer {
  border-bottom-left-radius: 16px !important;
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .map.footer {
    margin-left: 0;
    border-radius: 0 !important;
  }
  .map.footer .ymaps-2-1-79-map {
    border-radius: 0;
  }
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy_footer_logo {
    width: 120px;
  }
}

.ymaps-2-1-79-map {
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

body {
  background-color: #E9E9E9;
}

a {
  font-size: 18px;
  color: #1E2329;
  text-decoration: none;
}

.ardy--section-image-wrapper img {
  width: 100%;
  max-width: 700px;
  border-radius: 25px;
}

.ardy--gray-bottom-border {
  border-bottom: 1px solid #6F7276;
}

.ardy--primary-box-shadow, .ardy--course-list-container .ardy--course-card, .ardy--course-container .ardy--course-card, .ardy--course-container .ardy--marketing-card, .segmented--btn .button.active, .ardy--card {
  box-shadow: 0px 8px 20px 0px rgba(30, 35, 41, 0.4);
}

.ardy--image-background, .ardy--course-list-container .ardy--it-list-card .ardy--it-list-image, .ardy--course-list-container .ardy--design-list-card .ardy--design-list-image, .ardy--course-list-container .ardy--marketing-list-card .ardy--marketing-list-image, .ardy--course-container .ardy--marketing-card .ardy--marketing-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ardy--flex-1 {
  flex: 1;
}

.ardy--w-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.ardy--aside-list {
  list-style: none;
  padding: 0;
}
.ardy--aside-list li {
  padding: 10px 0;
  display: flex;
  align-items: start;
}
.ardy--aside-list li img {
  margin-right: 20px;
  margin-top: 8px;
}

.ardy--side-section, .footer {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 4rem 3rem;
}
@media (min-width: 768px) {
  .ardy--side-section, .footer {
    margin-left: 4rem;
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--side-section, .footer {
    margin-left: 0;
    border-radius: 0;
  }
}

.ardy--negative-section {
  margin-bottom: -40px;
}

.ardy--designed-image,
.ardy--designed-video {
  position: relative;
}
.ardy--designed-image::before,
.ardy--designed-video::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  background-color: #3965FF;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.ardy--designed-image img, .ardy--designed-image video,
.ardy--designed-video img,
.ardy--designed-video video {
  width: 100%;
}
.ardy--designed-image video,
.ardy--designed-video video {
  background-color: #000000;
}

.ardy--cursor-pointer {
  cursor: pointer;
}

.ardy--text-line-2, .ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info .ardy--course-body-card-title h5 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ardy--text-line-5, .ardy--course-topic-card .ardy--course-card-body .ardy--course-card-info .ardy--course-card-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ardy--modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .section-top {
    padding: 6rem 0;
  }
  .section {
    padding: 3rem 0;
  }
  .ardy--building-image-wrapper {
    height: 200px;
  }
  .ardy--building-image-wrapper img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 350px;
  }
  a {
    font-size: 18px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .section-top {
    padding: 7rem 0;
  }
  .section {
    padding: 4rem 0;
  }
  .ardy--building-image-wrapper img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 450px;
  }
  a {
    font-size: 16px !important;
  }
}
@media (min-width: 992px) {
  .section-top {
    padding: 8rem 0;
  }
  .section {
    padding: 5rem 0;
  }
  .ardy--building-image-wrapper img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 650px;
  }
  a {
    font-size: 18px !important;
  }
}
.fz-12 {
  font-size: 12px;
}

.fz-14 {
  font-size: 14px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fz-20 {
  font-size: 20px;
}

.fz-22 {
  font-size: 22px;
}

.fz-24 {
  font-size: 24px;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ardy--custom-carousel .slider-control-bottomcenter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ardy--custom-carousel .slider-control-bottomcenter ul {
  top: 33px !important;
}
.ardy--custom-carousel .slider-control-bottomcenter ul .paging-item button svg {
  height: 6px;
  width: 6px;
  margin: 2px;
}
.ardy--custom-carousel .slider-control-bottomcenter ul .active button svg {
  fill: #3965FF;
  width: 45px;
  background-color: #3965FF;
  border-radius: 5px;
}

.ardy--standart-carousel .slider-control-bottomcenter {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ardy--standart-carousel .slider-control-bottomcenter ul .paging-item button svg {
  height: 6px;
  width: 6px;
  margin: 2px;
}
.ardy--standart-carousel .slider-control-bottomcenter ul .active button svg {
  fill: #3965FF;
  width: 45px;
  background-color: #3965FF;
  border-radius: 5px;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--custom-carousel {
    padding-bottom: 48px;
  }
  .slider-control-bottomcenter {
    justify-content: center !important;
  }
}
.ardy--text-white-wrapper * {
  color: #ffffff !important;
}

.word-break-break-all {
  word-break: break-all;
}

.word-break-break-word {
  word-break: break-word;
}

.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.max-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;
}

.Toastify__toast {
  border-radius: 15px;
}

.ardy--custom-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.ardy--custom-play-btn svg {
  width: 45px;
}

.Toastify__toast-container {
  z-index: 10000;
}

.warning-svg path {
  stroke: #E02218;
}

.success-svg path {
  stroke: #169F00;
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.syllabus-accordion-item .accordion-collapse {
  padding-left: 0 !important;
}
.syllabus-accordion-item button {
  color: #1E2329;
  font-weight: 500 !important;
  line-height: normal;
  font-size: 24px !important;
  padding: 27px 10px 27px 0;
}

.faq-accordion-item-transparent {
  background-color: transparent;
}
.faq-accordion-item-transparent .accordion-collapse {
  padding-left: 0 !important;
}
.faq-accordion-item-transparent * {
  background: transparent !important;
}
.faq-accordion-item-transparent * button {
  color: #1E2329;
  font-weight: 700 !important;
  line-height: 22px;
  font-size: 16px !important;
  padding: 27px 10px 27px 0;
}

.faq-more {
  color: #1E2329;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.benefits-block {
  background-color: #F4F4F4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 64px 64px 64px 30px;
  z-index: -1;
  position: relative;
  right: -52px;
}
@media (max-width: 992px) {
  .benefits-block {
    right: 0;
  }
}

.show-more-less-clickable {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  text-decoration: none !important;
}

.pointer-events-none {
  pointer-events: none;
}

.empty-accordion {
  pointer-events: none;
}
.empty-accordion h2 button::after {
  display: none;
}

.ardy--paddingX-block {
  padding: 0 5rem;
}
@media (min-width: 375px) and (max-width: 992px) {
  .ardy--paddingX-block {
    padding: 0 2rem;
  }
}

.ardy--padding-right-block {
  padding-right: 7rem;
  padding-left: 2rem;
}
@media (min-width: 375px) and (max-width: 768px) {
  .ardy--padding-right-block {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 992px) {
  .ardy--choose-btn {
    width: 100% !important;
  }
}

.header {
  transition: transform 1s ease;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #E9E9E9;
  z-index: 999;
  animation: slide-top 0.2s ease-in both;
}
.fixed-header .navbar-brand img {
  max-width: 101px;
}

@keyframes slide-top {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.fixed-header.shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-slide-current .slide-visible {
  margin: 0 16px !important;
}

.without-checkbox::after {
  display: none !important;
}

.disabled-btn {
  opacity: 0.4;
  pointer-events: none;
}

.cover-decoration {
  position: absolute;
  width: calc(100vw - 200px);
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: -1;
  max-height: 100vh;
}

.cover-profile-decoration {
  position: fixed;
  z-index: -1;
}

.cover-ardy--section-image-wrapper img,
.cover-ardy--section-image-wrapper video {
  border-radius: 10px;
}
.cover-ardy--section-image-wrapper img {
  width: 100%;
}
.cover-ardy--section-image-wrapper video {
  width: 100% !important;
}

.ardy--custom-section-video-player {
  position: relative;
  display: flex;
  justify-content: end;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.ardy--custom-course-video-player {
  width: 100% !important;
  height: 100% !important;
}
.ardy--custom-course-video-player .ardy--custom-course-video-poster {
  max-height: 400px;
}
.ardy--custom-course-video-player video {
  display: block;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .slider-wrapper {
    position: relative;
    margin-left: 4rem;
  }
  .slider-wrapper .carousel-item {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 468px;
  }
  .slider-wrapper img {
    width: 100%;
  }
  .cover-profile-decoration {
    top: -250px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .cover-profile-decoration {
    top: -10%;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
  }
  .slider-wrapper {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .slider-wrapper {
    position: relative;
    right: 4rem;
    padding: 0 0 0 4rem;
    top: -50%;
    width: 100%;
  }
  .slider-wrapper img {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ardy--course-container {
    margin-left: 4rem;
    border-radius: 8px 0 0 8px;
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--course-container {
    margin-left: 0;
    border-radius: 0;
  }
}
.ardy--course-container .ardy--marketing-card {
  position: relative;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: 1;
  overflow: hidden;
}
.ardy--course-container .ardy--marketing-card .ardy--card-body {
  position: relative;
}
.ardy--course-container .ardy--marketing-card .ardy--card-body img {
  position: absolute;
  left: 25px;
  top: 25px;
}
.ardy--course-container .ardy--marketing-card .ardy--marketing-image {
  background-image: url(/src/assets/images/marketing-main.png);
}
.ardy--course-container .ardy--course-intensive,
.ardy--course-container .ardy--course-reguliar {
  margin-left: 3rem;
  position: relative;
}
@media (max-width: 991px) {
  .ardy--course-container .ardy--course-intensive,
  .ardy--course-container .ardy--course-reguliar {
    margin-left: 0;
  }
}
.ardy--course-container .ardy--course-intensive::after,
.ardy--course-container .ardy--course-reguliar::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  left: -35px;
  border: 3px solid #D8D8D8;
  transform: translateY(-50%);
}
.ardy--course-container .ardy--course-intensive::after {
  background-color: #D8D8D8;
}
.ardy--course-container .ardy--course-card {
  padding: 1.5rem 0;
  background-color: #ffffff;
  margin-left: auto;
  border-radius: 8px 0 0 8px;
  margin-top: -5px;
  width: 100%;
  position: relative;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--course-card > div {
    padding: 0 1rem;
  }
  .ardy--marketing-card .ardy--card-body,
  .ardy--design-card .ardy--card-body,
  .ardy--it-card .ardy--card-body {
    padding: 2.25rem 5rem !important;
  }
  .ardy--marketing-card .ardy--marketing-image,
  .ardy--marketing-card .ardy--design-image,
  .ardy--marketing-card .ardy--it-image,
  .ardy--design-card .ardy--marketing-image,
  .ardy--design-card .ardy--design-image,
  .ardy--design-card .ardy--it-image,
  .ardy--it-card .ardy--marketing-image,
  .ardy--it-card .ardy--design-image,
  .ardy--it-card .ardy--it-image {
    padding: 4.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ardy--course-card button {
    width: auto !important;
  }
  .ardy--course-card > div {
    padding: 0 2.5rem;
  }
  .ardy--marketing-card .ardy--card-body,
  .ardy--design-card .ardy--card-body,
  .ardy--it-card .ardy--card-body {
    padding: 3.25rem 6rem !important;
  }
  .ardy--marketing-card .ardy--marketing-image,
  .ardy--marketing-card .ardy--design-image,
  .ardy--marketing-card .ardy--it-image,
  .ardy--design-card .ardy--marketing-image,
  .ardy--design-card .ardy--design-image,
  .ardy--design-card .ardy--it-image,
  .ardy--it-card .ardy--marketing-image,
  .ardy--it-card .ardy--design-image,
  .ardy--it-card .ardy--it-image {
    padding: 4.25rem;
  }
}
@media (min-width: 992px) {
  .ardy--marketing-card,
  .ardy--design-card,
  .ardy--it-card {
    display: flex;
    justify-content: space-between;
  }
  .ardy--marketing-card .ardy--marketing-image,
  .ardy--marketing-card .ardy--design-image,
  .ardy--marketing-card .ardy--it-image,
  .ardy--design-card .ardy--marketing-image,
  .ardy--design-card .ardy--design-image,
  .ardy--design-card .ardy--it-image,
  .ardy--it-card .ardy--marketing-image,
  .ardy--it-card .ardy--design-image,
  .ardy--it-card .ardy--it-image {
    width: 50%;
  }
  .ardy--marketing-card .ardy--card-body,
  .ardy--design-card .ardy--card-body,
  .ardy--it-card .ardy--card-body {
    padding: 4.25rem 6rem !important;
    max-width: 50%;
  }
  .ardy--course-card > div {
    padding: 0 4rem;
  }
}
@media (max-width: 576px) {
  .course-lecturer-image {
    height: 250px !important;
  }
}

.ardy--course-list-container {
  position: relative;
}
.ardy--course-list-container .ardy--marketing-list-card .ardy--marketing-list-image {
  background-image: url(/src/assets/images/marketing.png);
}
.ardy--course-list-container .ardy--design-list-card .ardy--design-list-image {
  background-image: url(/src/assets/images/design.png);
}
.ardy--course-list-container .ardy--it-list-card .ardy--it-list-image {
  background-image: url(/src/assets/images/it.png);
}
.ardy--course-list-container::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  bottom: -50px;
  left: 150px;
  background-color: #D8D8D8;
}
.ardy--course-list-container .ardy--marketing-list-card,
.ardy--course-list-container .ardy--design-list-card,
.ardy--course-list-container .ardy--it-list-card {
  margin-bottom: -25px;
}
.ardy--course-list-container .ardy--card-body {
  display: flex;
  align-items: start;
}
.ardy--course-list-container .ardy--course-card {
  padding: 1.5rem;
  background-color: #ffffff;
  margin-left: auto;
  border-radius: 8px 0 0 8px;
  margin-bottom: 15px;
  z-index: 1;
  position: relative;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .ardy--course-list-container .ardy--full-course-card {
    width: 100% !important;
  }
  .ardy--course-list-container .ardy--course-card {
    width: calc(100vw - 3rem);
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--design-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--it-list-card .ardy--card-body {
    padding: 2.25rem 1.75rem !important;
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--it-list-image {
    padding: 4.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ardy--course-list-container .ardy--course-card {
    width: calc(100vw - 3rem);
  }
  .ardy--course-list-container .ardy--course-card button {
    width: auto !important;
    min-width: 165px;
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--design-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--it-list-card .ardy--card-body {
    padding: 2.25rem 1.75rem !important;
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--it-list-image {
    padding: 4.25rem;
  }
}
@media (min-width: 992px) {
  .ardy--course-list-container .ardy--course-card {
    max-width: calc(100vw - 300px);
  }
  .ardy--course-list-container .ardy--course-card button {
    min-width: 200px;
  }
  .ardy--course-list-container .ardy--course-card > div {
    padding-right: 4vw;
  }
  .ardy--course-list-container .ardy--marketing-list-card,
  .ardy--course-list-container .ardy--design-list-card,
  .ardy--course-list-container .ardy--it-list-card {
    display: flex;
    justify-content: space-between;
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--marketing-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--design-list-card .ardy--it-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--marketing-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--design-list-image,
  .ardy--course-list-container .ardy--it-list-card .ardy--it-list-image {
    width: 50%;
  }
  .ardy--course-list-container .ardy--marketing-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--design-list-card .ardy--card-body,
  .ardy--course-list-container .ardy--it-list-card .ardy--card-body {
    padding: 4.25rem 6rem !important;
    max-width: 50%;
  }
}
.ardy--login-container::after {
  content: "";
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  background-color: #000000;
  opacity: 0.8;
}
.ardy--login-container .ardy--login-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  background-color: #F4F4F4;
  border-radius: 16px;
}
.ardy--login-container .ardy--login-wrapper .ardy--login-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.ardy--login-container .ardy--login-wrapper .ardy--login-body {
  padding: 48px;
}
.ardy--login-container .ardy--login-footer {
  margin-top: 2rem;
  text-align: center;
}
.ardy--login-container .ardy--login-footer p {
  font-size: 16px;
}

.ardy--aler-container {
  display: flex;
  align-items: center;
  padding: 32px 64px;
  position: fixed;
  width: 100%;
  z-index: 10000;
  justify-content: space-between;
  background-color: #1E2329;
  bottom: -100%;
  transition: 0.3s;
  border-radius: 16px 16px 0px 0px;
}
.ardy--aler-container p {
  color: #ffffff;
  margin: 0;
}
.ardy--aler-container.active {
  bottom: 0;
}

.ardy--alert .ardy--alert-line {
  position: absolute;
  width: 12px;
  height: 100%;
  left: 0;
  top: 0;
}
.ardy--alert .ardy--alert-error-line {
  background-color: #E02218;
}
.ardy--alert .ardy--alert-success-line {
  background-color: #169F00;
}

.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 10001;
}

.scale-down-ver-bottom {
  animation: scale-down-ver-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 50vh;
  background-color: #000000;
  z-index: 10002;
}

.scale-down-ver-top {
  animation: scale-down-ver-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 50vh;
  background-color: #000000;
  z-index: 10002;
}
@keyframes scale-down-ver-bottom {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 100%;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 100%;
  }
}
@keyframes scale-down-ver-top {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }
}
.loader-line-block {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.loader-line-block img {
  max-width: 180px;
}
@media (min-width: 375px) and (max-width: 767.98px) {
  .loader-line-block img {
    max-width: 160px;
  }
}

.loader-line {
  width: 100%;
  height: 3px;
  position: fixed;
  overflow: hidden;
  background-color: #6a6a6a;
  z-index: 10003;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 100%;
  width: 40%;
  background-color: #E9E9E9;
  animation: lineAnim 1s linear infinite;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
.partners-swiper .swiper {
  margin: 0;
  height: 60px;
}
.partners-swiper .partner-logo {
  height: 120px;
}
@media (max-width: 992px) {
  .partners-swiper .partner-logo {
    height: 100px;
  }
}

@font-face {
  font-family: "Mardoto";
  src: url(/src/fonts/Mardoto/Mardoto-Medium.eot);
  src: url(/src/fonts/Mardoto/Mardoto-Medium.eot?#iefix) format("embedded-opentype"), url(/src/fonts/Mardoto/Mardoto-Medium.woff2) format("woff2"), url(/src/fonts/Mardoto/Mardoto-Medium.woff) format("woff"), url(/src/fonts/Mardoto/Mardoto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mardoto";
  src: url(/src/fonts/Mardoto/Mardoto-Regular.eot);
  src: url(/src/fonts/Mardoto/Mardoto-Regular.eot?#iefix) format("embedded-opentype"), url(/src/fonts/Mardoto/Mardoto-Regular.woff2) format("woff2"), url(/src/fonts/Mardoto/Mardoto-Regular.woff) format("woff"), url(/src/fonts/Mardoto/Mardoto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mardoto";
  src: url(/src/fonts/Mardoto/Mardoto-Light.eot);
  src: url(/src/fonts/Mardoto/Mardoto-Light.eot?#iefix) format("embedded-opentype"), url(/src/fonts/Mardoto/Mardoto-Light.woff2) format("woff2"), url(/src/fonts/Mardoto/Mardoto-Light.woff) format("woff"), url(/src/fonts/Mardoto/Mardoto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Intl";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Regular.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Regular.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-Regular.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-Regular.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Int'l";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Light.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Light.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-Light.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-Light.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Intl";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Bold.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Bold.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-Bold.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-Bold.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Int'l";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Book.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Book.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-Book.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-Book.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-Book.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Intl";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Int'l";
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Medium.eot);
  src: url(/src/fonts/SuisseIntl/SuisseIntl-Medium.eot?#iefix) format("embedded-opentype"), url(/src/fonts/SuisseIntl/SuisseIntl-Medium.woff2) format("woff2"), url(/src/fonts/SuisseIntl/SuisseIntl-Medium.woff) format("woff"), url(/src/fonts/SuisseIntl/SuisseIntl-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.carousel-indicators button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 50%;
  background-color: #1E2329 !important;
}
.carousel-indicators button.active {
  width: 50px !important;
  border-radius: 10px;
  background-color: #3965FF !important;
}

.mt-8 {
  margin-top: 7rem;
}

.accordion-item .accordion-body > div {
  border-bottom: 1px solid #1E2329;
  padding: 1rem 0;
}
.accordion-item .accordion-body > div:last-child {
  border-bottom: none;
}
.accordion-item .accordion-body .accordion-body-title {
  font-size: 18px;
}
.accordion-item .accordion-body .accordion-body-list {
  list-style: none;
  padding-left: 3rem;
}
.accordion-item .accordion-body .accordion-body-list li {
  padding: 0.75rem 0;
}
.accordion-item:last-child .accordion-button {
  border-bottom: 2px solid #6F7276;
}
.accordion-item .accordion-button {
  background-color: #D8D8D8;
  border-top: 2px solid #6F7276;
}
.accordion-item .accordion-collapse {
  background-color: #D8D8D8;
  padding: 0 3rem;
}

.ardy--faq-container .accordion-collapse {
  padding: 1rem 0 1rem 3rem;
}
.ardy--faq-container .accordion-collapse .accordion-body {
  border-radius: 8px;
  padding: 24px 32px;
}

@media (min-width: 992px) {
  .container-fluid {
    padding: 0 4rem;
  }
}
@media (max-width: 991.98px) {
  .accordion-collapse {
    padding: 0 !important;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.text_error {
  color: #E02218 !important;
}

.border_error {
  border-color: #E02218 !important;
  border-width: 2px !important;
}/*# sourceMappingURL=styles.css.map */