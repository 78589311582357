.ardy--underline{
        max-width: 100px;
        border-top: 2.5px solid $black;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea{
    border-radius: 8px;
    border: 2px solid $derivatives-bkg-lvl-3;
    background: $white;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    @if $selected-language == "english" {
        @extend .font-suisseint-regular;
        font-style: 450 !important;
    }@else{
        @extend .font-mardoto-regular;
    }
}

.ardy--icon-input{
    position: relative;
    img{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        padding: 10px;
        cursor: pointer;
        opacity: 0.7;
    }
    input{
        padding-right: 55px !important;
    }
}

@media (min-width: 375px) and (max-width: 991.98px){
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    textarea{
        padding: 8px 16px;
        font-size: $font-size-16;
    }
}

@media (min-width: 992px){
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    textarea {
        padding: 14px 16px;
        font-size: $font-size-18;
    }
}